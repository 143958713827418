import ReadMore from "@components/ReadMore/ReadMore"
import Animation from "@components/elements/Animation"
import CheckCircle from "@components/icons/CheckCircle"
import { formatNumber, scrollToID } from "@lib/utils"
import React, { useEffect, useState } from "react"
import "./PropertyInfo.scss"
import CurrencyPrice from "@components/CurrencyPrice/CurrencyPrice"
import { isMobile, isTablet } from "react-device-detect"
import SizeChange from "@components/CurrencyPrice/SizeChange"
import Arrows4Icon from "@components/icons/Arrows4Icon"
import BathIcon from "@components/icons/BathIcon"
import BedIcon from "@components/icons/BedIcon"
import PropertyNegoCard from "@components/PropertyNegoCard/PropertyNegoCard"
import useDeviceMedia from "@hooks/useDeviceMedia"
import { isEmpty } from "lodash"

const PropertyInfo = ({ property, propertyData, propertyURL, message, email, team_data, team_key, name }) => {
  const {
    price,
    description,
    building,
    search_type,
    display_address,
    bedroom,
    bathroom,
    floorarea_max,
    crm_id,
    long_description,
    accommodation_summary,
    extra,
    department,
  } = property

  const isCommercial = department === "commercial"

  const buildingText = property?.building[0] || "Property"
  const action = property?.search_type === "sales" ? "for sale" : "for rent"
  const bedrooms = property?.bedroom
  const bedroomsText = Number(bedrooms) > 1 ? "bedrooms" : "bedroom"
  const address = property?.display_address
  const priceText = formatNumber(property?.price)

  const propertyType = building[0] || "Property"
  const isSales = search_type === "sales"
  const amenties = accommodation_summary || []
  const [count, setCount] = useState(0);
  const {
    isAboveLargeScreen, isMobile
  } = useDeviceMedia()
  useEffect(() => {
    const elements = document.querySelectorAll('.key-info-item');
    setCount(elements.length);

    // Reset all border-bottom styles first
    elements.forEach(el => {
      el.style.borderBottom = "1px solid #F0F3F8"; 
    });

    // Conditionally apply border-bottom: 0 to the last few elements
    // if (elements.length > 0 && !isTablet && !isMobile) {
    //   const lastRowStartIndex = elements.length - (elements.length % 3 === 0 ? 3 : elements.length % 3);
    //   for (let i = lastRowStartIndex; i < elements.length; i++) {
    //     elements[i].style.borderBottom = '0';
    //   }
    // }
    // else {
      const lastRowStartIndex = elements.length - (elements.length % 2 === 0 ? 2 : elements.length % 2);
      for (let i = lastRowStartIndex; i < elements.length; i++) {
        elements[i].style.borderBottom = '0';
      }

    // }
  });
  return (
    <div className="property-info-wrapper">
      <div className="property-info-container">
        <h1
          style={{
            position: "absolute",
            top: "0px",
            opacity: "0",
            fontSize: "10px",
          }}
        >
          {`${buildingText} ${action} with ${bedrooms} ${bedroomsText} in ${address} at AED ${priceText} [${crm_id}]`}
        </h1>
        <h2 className="price">
          {/* AED {formatNumber(price)} */}
          <CurrencyPrice price={price} />
        </h2>
        {isSales && (
          <button
            to="/"
            className="mortgage-link"
            onClick={() => {
              scrollToID("mortgage-calculator", 100)
            }}
          >
            Calculate your mortgage repayments
          </button>
        )}
        <div className="description-section">
          <p className="description1">{description}</p>
          <p className="description2">{`${propertyType} ${
            isSales ? "for Sale" : "for rent"
          } in ${display_address}`}</p>
        </div>
        <div className="info-section">
        {!(isCommercial && bedroom == "0") && (propertyType != "land") && (propertyType != "Land") && (propertyType != "whole building") && (propertyType != "Whole building") &&(
            <p className="bedrooms">
              <BedIcon />
              <span>{bedroom == "0" ? "Studio" : bedroom} {Number(bedroom) > 1 ? "Beds" : "Bed"}</span>
            </p>
          )}
          {bathroom != "0" && (
            <p className="bathrooms">
              <BathIcon />
              <span>{bathroom} {Number(bathroom) > 1 ? "Baths" : "Bath"}</span>
            </p>
          )}
          {floorarea_max && (
            <p className="size">
              <Arrows4Icon />
              <span><SizeChange sizeVal={floorarea_max} /></span>
            </p>
           )} 
        </div>
        <div className="key-info-section">
          <p className="heading">Key Information</p>
          <div className="key-infos">
            {propertyType &&
            <Animation animateInType="up" wrapperClassName="key-info-item">
              <p className="label">Property Type</p>
              <p className="value">{propertyType}</p>
            </Animation>}
            <Animation animateInType="up" wrapperClassName="key-info-item">
              <p className="label">Purpose</p>
              <p className="value">{isSales ? "For Sale" : "For rent"}</p>
            </Animation>
            {/* {!(isCommercial && bedroom === 0) && (propertyType !== "Land") &&(
              <Animation animateInType="up" wrapperClassName="key-info-item">
                <p className="label">Bedroom(s)</p>
                <p className="value">{bedroom === 0 && propertyType !== "Land" ? "Studio" : bedroom}</p>
              </Animation>
            )}
            {bathroom !== 0 && (
              <Animation animateInType="up" wrapperClassName="key-info-item">
                <p className="label">Bathroom(s)</p>
                <p className="value">{bathroom}</p>
              </Animation>
            )}
            {floorarea_max &&
            <Animation animateInType="up" wrapperClassName="key-info-item">
              <p className="label">Area/Size</p>
              <p className="value"><SizeChange sizeVal={floorarea_max} /></p>
            </Animation>} */}
           {extra?.completion_status &&
            <Animation animateInType="up" wrapperClassName="key-info-item">
              <p className="label">Completion</p>
              <p className="value">{extra?.completion_status}</p>
            </Animation>}
            {extra?.furnishing_type &&
            <Animation animateInType="up" wrapperClassName="key-info-item">
              <p className="label">Furnishing Type</p>
              <p className="value">{extra?.furnishing_type}</p>
            </Animation>}
            {crm_id &&
            <Animation animateInType="up" wrapperClassName="key-info-item">
              <p className="label">Property ID</p>
              <p className="value">{crm_id}</p>
            </Animation>}
            {extra?.property_condition &&
            <Animation animateInType="up" wrapperClassName="key-info-item">
              <p className="label">Property Condition</p>
              <p className="value">{extra?.property_condition}</p>
            </Animation>}
            {extra?.property_managed_by_us &&
            <Animation animateInType="up" wrapperClassName="key-info-item">
              <p className="label">Property Managed by Us</p>
              <p className="value">{extra?.property_managed_by_us}</p>
            </Animation>}
            {extra?.views &&
            <Animation animateInType="up" wrapperClassName="key-info-item">
              <p className="label">Views</p>
              <p className="value">{extra?.views}</p>
            </Animation>}
          </div>
        </div>
        <div className="divider" />

        <Animation
          animateInType="up"
          className="long-description-section"
          id="contentsection-property"
        >
          <p className="heading">Description</p>
          <ReadMore
            content={long_description}
            height={260}
            className="long-description"
            scrollToId="contentsection-property"
          />
        </Animation>
        {!isEmpty(property) && !isAboveLargeScreen &&
        <Animation
              className="right-section"
              wrapperClassName="right-section-wrap"
            >
             
              <PropertyNegoCard
                negoData={property.crm_negotiator_id}
                negoID={property.crm_negotiator_id?.brn_number}
                property={propertyData}
                propertyURL={propertyURL}
                message={message}
                email={email}
                team_data={team_data}
                team_key={team_key}
                name={name}
                link_to_employee={property?.link_to_employee}

              />
            </Animation> }

{!isMobile &&        <div className="divider" />}

        {amenties?.length> 0 && <> <Animation animateInType="up" className="property-features-section">
          <p className="heading">Amenities</p>
          <div className="features-wrap">
            {amenties.map((item, index) => (
              <Animation
                animateInType="up"
                className="feature-item"
                key={`${item}amenity`}
                delay={index * 50}
              >
                <CheckCircle />
                <p className="feature-text">{item}</p>
              </Animation>
            ))}
          </div>
        </Animation>
        <div className="divider" />
        </>}
      </div>
    </div>
  )
}

export default PropertyInfo
